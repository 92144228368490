import Masonry from "masonry-layout";

export default class Portfolio {
  constructor() {
    this.count = $(".portfolio-items > div").length;

    if (this.count > 6) {
      $(".more_btn_wrapper").removeClass("hidden");
    }

    for (let i = 0; i < 6; i++) {
      $(".grid").append($(".portfolio-items > div").eq(i));
    }

    $(".more_btn_wrapper .more_btn").on("click", e => {
      //$(".grid").empty();
      for (let i = 0; i < 6; i++) {
        $(".grid").append($(".portfolio-items > div").eq(i));
      }

      this.count = $(".portfolio-items > div").length;
      if (this.count > 6) {
        $(".more_btn_wrapper").removeClass("hidden");
      } else {
        $(".more_btn_wrapper").addClass("hidden");
      }
      return false;
    });

    if ($(".grid").length) {
      var msnry = new Masonry(".grid", {
        itemSelector: ".grid_portfolio .grid .col-md-4",
        columnWidth: ".grid_portfolio .grid .col-md-4"
      });
    }

    $("#request-call").on("submit", function (e) {
      e.preventDefault();
      $.ajax({
        method: "POST",
        url: "https://www.7x7.by/portfolio.html",
        data: $("#request-call").serialize()
      }).done(function (msg) {
        $("#request-call").html(`<p>Сообщение успешно отправлено!</p>`);
      });
      return false;
    });
  }
}
