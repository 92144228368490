/** global var productItems */

import PhotoSwipe from 'photoswipe';
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default';

export default class Product {
    constructor() {
        if( ! document.querySelector('#main-slider') ) {
            return;
        }

        this.maxWidth = 400;
        this.pswpElement = document.querySelector('.pswp');
        this.items = productItems || [];
        this.messageNode = null;
        this.handler = null;

        this.setHandler();
        setTimeout( () => {
            this.addMessage();
        }, 1000 );
    }

    addMessage() {
        const container = document.querySelector('.zoom-container');
        if( container ) {
            this.messageNode = document.createElement('span');
            this.messageNode.innerHTML = `Нажмите на изображение, чтобы увеличить`;
            this.messageNode.classList.add('zoom-message');
            container.appendChild( this.messageNode );
            this.hideMessage();
        }
    }

    hideMessage() {
        let opacity = 0.95;
        setTimeout(() => {
            this.handler = setInterval(() => {
                opacity -= 0.19;
                this.messageNode.style.opacity = opacity;

                if( opacity <= 0 ) {
                    clearInterval( this.handler );
                    this.messageNode.style.display = 'none';
                    this.messageNode.style.opacity = 0;
                }
            }, 25);
        }, 5000);
    }

    setHandler() {

        if( ! window.matchMedia(`(max-width: ${this.maxWidth}px)`).matches) {
            return;
        }

        const elements = document.querySelectorAll('.zoom-popup');

        if( ! elements.length ) {
            return;
        }

        elements.forEach( (element, index) => {
            element.addEventListener('click', e => {
                e.preventDefault();

                this.initGallery( { 
                    pswpElement: this.pswpElement, 
                    PhotoSwipeUI_Default, 
                    items: this.items, 
                    options: { index }
                } );
            });
        });
    }

    initGallery( { pswpElement, PhotoSwipeUI_Default, items, options } ) {
        if( ! pswpElement ) {
            return;
        }

        // Initializes and opens PhotoSwipe
        const gallery = new PhotoSwipe( pswpElement, PhotoSwipeUI_Default, items, options);
        gallery.init();
    }
}