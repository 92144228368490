/**
 * Global var params
 */
let URI = require("urijs");

export default class Collections {

  constructor(container, index) {
    this.uri = new URI(window.location.href).search(true);
    this.containerSelector = container;
    this.containerCollection = null;
    this.containerAlphabet = null;
    this.alphabet = [];
    this.index = index;

    /**
     * Call method init
     */
    this.init();
  }

  init() {
    $(this.containerSelector).append(
      `<div class="alphabet_checkbox_wrapper"></div>`
    );
    $(this.containerSelector).append(
      `<div class="search-panel__content__tab__params__checklist small" data-class="collection" data-title="Коллекция"></div>`
    );

    this.containerAlphabet = $(this.containerSelector)
      .find(".alphabet_checkbox_wrapper")
      .eq(0);
    this.containerCollection = $(this.containerSelector)
      .find('[data-class="collection"]')
      .eq(0);

    this.setListAlghabet();
    this.initEvent();
  }

  setListAlghabet() {
    params.wallpaper.collection.list = params.wallpaper.collection.list.sort();

    params.wallpaper.collection.list.forEach((item, key) => {
      if (this.alphabet.indexOf(item[0].toString().toLowerCase()) === -1) {
        this.alphabet.push(item[0].toString().toLowerCase());
      }

      $(this.containerCollection).append(
        `<div class="checkbox" data-char="${item[0].toLocaleLowerCase()}">
					<input id="wallpaper-collection-${
            this.index
          }-${key}" type="checkbox" name="collection[]" value="${item}" ${
          this.uri["collection[]"] !== undefined &&
          this.uri["collection[]"].indexOf(item) !== -1
            ? 'checked="checked"'
            : ""
        } />
					<label for="wallpaper-collection-${this.index}-${key}">${item}</label>
				</div>`
      );
    });

    this.alphabet = this.alphabet.sort();

    this.alphabet.forEach(char => {
      $(this.containerAlphabet).append(
        `<div class="checkbox">
					<input type="checkbox" id="wallpaper-alphabet-${
            this.index
          }-${char}" value="${char}">
					<label for="wallpaper-alphabet-${this.index}-${char}">${char}</label>
				</div>`
      );
    });
  }

  initEvent() {
    $('.alphabet_checkbox_wrapper input[type="checkbox"]').on(
      "change",
      this.toggleElements
    );
  }

  toggleElements() {
    let chars = [];

    $('.alphabet_checkbox_wrapper input[type="checkbox"]:checked').each(
      function() {
        chars.push($(this).prop("value"));
      }
    );

    if (chars === []) {
      $("[data-char]").show();
    } else {
      $("[data-char]").hide();
      chars.forEach(char => {
        $(`[data-char="${char}"]`).show();
      });
    }
  }
}
