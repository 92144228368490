export default class Wrapper {
  constructor() {
    this.tabs = [
      {
        id: 0,
        data: {
          typeroom: {
            name: "Комнат",
            values: [],
            many: {
              "2,3,4": "ы",
              "5,6,7,8,9,0": ""
            }
          },
          p1: {
            name: "Материал",
            values: [],
            many: {
              "2,3,4": "а",
              "1,5,6,7,8,9,0": "ов"
            }
          },
          p3: {
            name: "Стил",
            values: [],
            many: {
              "2,3,4": "я",
              "1,5,6,7,8,9,0": "ей"
            }
          },
          brand: {
            name: "Бренд",
            values: [],
            many: {
              "2,3,4": "а",
              "5,6,7,8,9,0": "ов"
            }
          },
          collection: {
            name: "Коллекци",
            values: [],
            many: {
              "2,3,4": "и",
              "5,6,7,8,9,0": "й"
            }
          },
          colors: {
            name: "Цвет",
            values: [],
            many: {
              "2,3,4": "а",
              "5,6,7,8,9,0": "ов"
            }
          }
        }
      },
      {
        id: 1,
        data: {
          typeroom: {
            name: "Комнат",
            values: [],
            many: {
              "2,3,4": "ы",
              "5,6,7,8,9,0": ""
            }
          },
          p1: {
            name: "Материал",
            values: [],
            many: {
              "2,3,4": "а",
              "1,5,6,7,8,9,0": "ов"
            }
          },
          p3: {
            name: "Стил",
            values: [],
            many: {
              "2,3,4": "я",
              "1,5,6,7,8,9,0": "ей"
            }
          },
          brand: {
            name: "Бренд",
            values: [],
            many: {
              "2,3,4": "а",
              "5,6,7,8,9,0": "ов"
            }
          },
          collection: {
            name: "Коллекци",
            values: [],
            many: {
              "2,3,4": "и",
              "5,6,7,8,9,0": "й"
            }
          },
          colors: {
            name: "Цвет",
            values: [],
            many: {
              "2,3,4": "а",
              "5,6,7,8,9,0": "ов"
            }
          },
          "country-list": {
            name: "Стран",
            values: [],
            many: {
              "2,3,4": "ы",
              "5,6,7,8,9,0": ""
            }
          },
          designcheckbox: {
            name: "Дизайн",
            values: [],
            many: {
              "2,3,4": "а",
              "5,6,7,8,9,0": "ов"
            }
          },
          appointment: {
            name: "Назначени",
            values: [],
            many: {
              "2,3,4": "я",
              "5,6,7,8,9,0": "й"
            }
          },
          stuffsearch: {
            name: "Состав",
            values: [],
            many: {
              "2,3,4": "а",
              "5,6,7,8,9,0": "ов"
            }
          },
          view_accessory: {
            name: "Вид",
            values: [],
            many: {
              "2,3,4": "а",
              "5,6,7,8,9,0": "ов"
            }
          },
          appointment_accessory: {
            name: "Назначени",
            values: [],
            many: {
              "2,3,4": "я",
              "5,6,7,8,9,0": "й"
            }
          }
        }
      },
      {
        id: 2,
        data: {
          typeroom: {
            name: "Комнат",
            values: [],
            many: {
              "2,3,4": "ы",
              "5,6,7,8,9,0": ""
            }
          },
          p1: {
            name: "Материал",
            values: [],
            many: {
              "2,3,4": "а",
              "1,5,6,7,8,9,0": "ов"
            }
          },
          p3: {
            name: "Стил",
            values: [],
            many: {
              "2,3,4": "я",
              "1,5,6,7,8,9,0": "ей"
            }
          },
          brand: {
            name: "Бренд",
            values: [],
            many: {
              "2,3,4": "а",
              "5,6,7,8,9,0": "ов"
            }
          },
          collection: {
            name: "Коллекци",
            values: [],
            many: {
              "2,3,4": "и",
              "5,6,7,8,9,0": "й"
            }
          },
          colors: {
            name: "Цвет",
            values: [],
            many: {
              "2,3,4": "а",
              "5,6,7,8,9,0": "ов"
            }
          },
          "country-list": {
            name: "Стран",
            values: [],
            many: {
              "2,3,4": "ы",
              "5,6,7,8,9,0": ""
            }
          },
          designcheckbox: {
            name: "Дизайн",
            values: [],
            many: {
              "2,3,4": "а",
              "5,6,7,8,9,0": "ов"
            }
          },
          appointment: {
            name: "Назначени",
            values: [],
            many: {
              "2,3,4": "я",
              "5,6,7,8,9,0": "й"
            }
          },
          stuffsearch: {
            name: "Состав",
            values: [],
            many: {
              "2,3,4": "а",
              "5,6,7,8,9,0": "ов"
            }
          },
          view_accessory: {
            name: "Вид",
            values: [],
            many: {
              "2,3,4": "а",
              "5,6,7,8,9,0": "ов"
            }
          },
          appointment_accessory: {
            name: "Назначени",
            values: [],
            many: {
              "2,3,4": "я",
              "5,6,7,8,9,0": "й"
            }
          }
        }
      },
      {
        id: 3,
        data: {
          typeroom: {
            name: "Комнат",
            values: [],
            many: {
              "2,3,4": "ы",
              "5,6,7,8,9,0": ""
            }
          },
          p1: {
            name: "Материал",
            values: [],
            many: {
              "2,3,4": "а",
              "1,5,6,7,8,9,0": "ов"
            }
          },
          p3: {
            name: "Стил",
            values: [],
            many: {
              "2,3,4": "я",
              "1,5,6,7,8,9,0": "ей"
            }
          },
          brand: {
            name: "Бренд",
            values: [],
            many: {
              "2,3,4": "а",
              "5,6,7,8,9,0": "ов"
            }
          },
          collection: {
            name: "Коллекци",
            values: [],
            many: {
              "2,3,4": "и",
              "5,6,7,8,9,0": "й"
            }
          },
          colors: {
            name: "Цвет",
            values: [],
            many: {
              "2,3,4": "а",
              "5,6,7,8,9,0": "ов"
            }
          },
          "country-list": {
            name: "Стран",
            values: [],
            many: {
              "2,3,4": "ы",
              "5,6,7,8,9,0": ""
            }
          },
          designcheckbox: {
            name: "Дизайн",
            values: [],
            many: {
              "2,3,4": "а",
              "5,6,7,8,9,0": "ов"
            }
          },
          appointment: {
            name: "Назначени",
            values: [],
            many: {
              "2,3,4": "я",
              "5,6,7,8,9,0": "й"
            }
          },
          stuffsearch: {
            name: "Состав",
            values: [],
            many: {
              "2,3,4": "а",
              "5,6,7,8,9,0": "ов"
            }
          },
          view_accessory: {
            name: "Вид",
            values: [],
            many: {
              "2,3,4": "а",
              "5,6,7,8,9,0": "ов"
            }
          },
          appointment_accessory: {
            name: "Назначени",
            values: [],
            many: {
              "2,3,4": "я",
              "5,6,7,8,9,0": "й"
            }
          }
        }
      },
      {
        id: 4,
        data: {
          typeroom: {
            name: "Комнат",
            values: [],
            many: {
              "2,3,4": "ы",
              "5,6,7,8,9,0": ""
            }
          },
          p1: {
            name: "Материал",
            values: [],
            many: {
              "2,3,4": "а",
              "1,5,6,7,8,9,0": "ов"
            }
          },
          p3: {
            name: "Стил",
            values: [],
            many: {
              "2,3,4": "я",
              "1,5,6,7,8,9,0": "ей"
            }
          },
          brand: {
            name: "Бренд",
            values: [],
            many: {
              "2,3,4": "а",
              "5,6,7,8,9,0": "ов"
            }
          },
          collection: {
            name: "Коллекци",
            values: [],
            many: {
              "2,3,4": "и",
              "5,6,7,8,9,0": "й"
            }
          },
          colors: {
            name: "Цвет",
            values: [],
            many: {
              "2,3,4": "а",
              "5,6,7,8,9,0": "ов"
            }
          },
          "country-list": {
            name: "Стран",
            values: [],
            many: {
              "2,3,4": "ы",
              "5,6,7,8,9,0": ""
            }
          },
          designcheckbox: {
            name: "Дизайн",
            values: [],
            many: {
              "2,3,4": "а",
              "5,6,7,8,9,0": "ов"
            }
          },
          appointment: {
            name: "Назначени",
            values: [],
            many: {
              "2,3,4": "я",
              "5,6,7,8,9,0": "й"
            }
          },
          stuffsearch: {
            name: "Состав",
            values: [],
            many: {
              "2,3,4": "а",
              "5,6,7,8,9,0": "ов"
            }
          },
          view_accessory: {
            name: "Вид",
            values: [],
            many: {
              "2,3,4": "а",
              "5,6,7,8,9,0": "ов"
            }
          },
          appointment_accessory: {
            name: "Назначени",
            values: [],
            many: {
              "2,3,4": "я",
              "5,6,7,8,9,0": "й"
            }
          }
        }
      }
    ];
  }

  bindAction() {
    $(".search-panel input:checkbox").on("click", e => {
      const $index = $(e.currentTarget)
        .closest(".search-panel__content__tab")
        .index();
      let $name = $(e.currentTarget).prop("name");
      $name = $name.substr(0, $name.length - 2);

      this.tabs[$index].data[$name].values = [];
      $(e.currentTarget)
        .closest(".search-panel__content__tab")
        .find(`input[name="${$(e.currentTarget).prop("name")}"]:checked`)
        .each((index, element) => {
          if (
            ["status", "only-discount"].indexOf($(element).prop("name")) === -1
          )
            this.tabs[$index].data[$name].values.push(
              $(element)
                .next()
                .html()
            );
        });

      this.outputPanel($index);
    });
    return this;
  }

  outputPanel($tab_id) {
    let $flgShow = false;
    for (let obj in this.tabs[$tab_id].data) {
      if (this.tabs[$tab_id].data[obj].values.length) {
        $flgShow = true;
      }
    }

    if ($flgShow) {
      const $tab_is_active = $(".search-panel__content__tab.active");
      const $wrapper = $tab_is_active.find(
        ".search-panel__content__tab_checked_wrapper > .checked_wrapper"
      );

      $wrapper.empty();
      const $names = [];

      $tab_is_active
        .find(".search-panel__content__tab__params input:checkbox")
        .each(($index, $element) => {
          let $name = $($element).prop("name");
          $name = $name.substr(0, $name.length - 2);
          if ($names.indexOf($name) === -1) {
            $names.push($name);
          }
        });

      $names.forEach($name => {
        if (
          this.tabs[$tab_id].data.hasOwnProperty($name) &&
          this.tabs[$tab_id].data[$name].values.length
        ) {
          $wrapper.append(
            `<div data-class="${$name}">
              <div class="checked_block">
                <div class="close_btn"></div>
                ${this.isPlural(
                  this.tabs[$tab_id].data[$name].values[0],
                  this.tabs[$tab_id].data[$name].name,
                  this.tabs[$tab_id].data[$name].values.length,
                  this.tabs[$tab_id].data[$name].many
                )}
              </div>
            </div>`
          );
        }
      });

      $(".search-panel .close_btn").on("click", e => {
        e.preventDefault();
        const $name = $(e.currentTarget)
          .closest("[data-class]")
          .data("class");

        $(e.currentTarget)
          .closest("[data-class]")
          .remove();
        this.removeParam($name);
      });

      $wrapper.parent().addClass("active");
    }
  }

  isPlural($value, $name, $count, $many) {
    let $output = "";
    if ($count == 1) {
      $output = $value;
    } else {
      Object.keys($many).forEach($key => {
        if ($key.indexOf($count % 10) !== -1) {
          $output = $count + " " + $name + $many[$key];
        }
      });
    }
    return $output;
  }

  removeParam($name) {
    const $tab_is_active = $(".search-panel__content__tab.active");
    const $index = $tab_is_active.index();
    $tab_is_active.find(`input[name="${$name}[]"]`).prop("checked", false);
    this.tabs[$index].data[$name].values = [];
  }

  getCheckedFilter() {
    const $tab_is_active = $(".search-panel__content__tab.active");
    const $tab_id = $tab_is_active.index();

    $(".search-panel__content__tab.active input:checkbox:checked").each(
      ($index, $element) => {
        let $name = $($element).prop("name");
        $name = $name.substr(0, $name.length - 2);

        const _name = $($element).next().html();

        if( this.tabs[$tab_id].data[$name].values.indexOf( _name ) === -1 ) {
          this.tabs[$tab_id].data[$name].values.push(_name);
        }
      }
    );

    console.log( this.tabs[$tab_id] );
  }

  static init() {
    const _this = new this();
    const $tab_id = $(".search-panel__content__tab.active").index();

    _this.getCheckedFilter();
    _this.bindAction().outputPanel($tab_id);
  }
}
