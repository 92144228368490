/**
 * Created by Lam on 03.10.17
*/

(function( $ ){
	var self = null,
	settings = {
		json : null
	},
	alphabet = {
		textile : { 
			'a' : [], 'b' : [], 'c' : [], 'd' : [], 
			'e' : [], 'f' : [], 'g' : [], 'h' : [],
			'i' : [], 'j' : [], 'k' : [], 'l' : [], 
			'm' : [], 'n' : [], 'o' : [], 'p' : [],
			'q' : [], 'r' : [], 's' : [], 't' : [], 
			'u' : [], 'v' : [], 'w' : [], 'x' : [],
			'y' : [], 'z' : [], '1' : [], '2' : [], 
			'3' : [], '4' : [], '5' : [], '6' : [],
			'7' : [], '8' : [], '9' : []
		},
		accessories : {
			'a' : [], 'b' : [], 'c' : [], 'd' : [], 
			'e' : [], 'f' : [], 'g' : [], 'h' : [],
			'i' : [], 'j' : [], 'k' : [], 'l' : [], 
			'm' : [], 'n' : [], 'o' : [], 'p' : [],
			'q' : [], 'r' : [], 's' : [], 't' : [], 
			'u' : [], 'v' : [], 'w' : [], 'x' : [],
			'y' : [], 'z' : [], '1' : [], '2' : [], 
			'3' : [], '4' : [], '5' : [], '6' : [],
			'7' : [], '8' : [], '9' : []
		}
	},
	selectChar,
	methods = {
		init : function( options ) {
			self = this;
			settings = $.extend( settings, options );
			for( let i in settings.json ) {
				alphabet[ settings.category ][ settings.json[i].collection[0].toLowerCase() ].push( settings.json[i] );
			}
			$( self ).each( function(){
				var ul = this;
				$( this ).after( '<div class="collection-list"></div>' );
				methods.showAlphabet( this );
				$( this ).find( 'a' ).on( 'click', function(){
					if( !$( this ).parent().hasClass( 'active' ) ){
						$( ul ).children( 'li' ).removeClass( 'active' );
						$( this ).parent().addClass( 'active' );
						selectChar = $( this ).data( 'char' );
						methods.showCollection( ul, $( this ).data( 'category' ) );
					}
					return false;
				} );
			});
		},
		decodeURL : function(){
			if( window.location.search == '' ) return;
			tmp = decodeURIComponent( window.location.search.replace( '?', '' ) ).replace( /\+/gi, ' ' ).split( /&/ );
			tmp.forEach( function( key ){
				var param = /[a-z]+/gi.exec( key )[0];
				if( search[ param ] === undefined || typeof search[ param ] !== 'object' )
					search[ param ] = [];
				search[ param ].push( /=(.*)/gi.exec( key )[1] );
			} );
		},
		showAlphabet : function( ul ){
			var keys = Object.keys( alphabet[ settings.category ] );
			keys.forEach( function( key ) {
				if( alphabet[ settings.category ][ key ].length > 0 ) {
					$( ul ).append( '<li><a href="#" class="text-uppercase" data-char="' + key + '" data-category="' + settings.category + '">' + key + '</a></li>' );
				}
			} );
			$( ul ).children( 'li' ).eq( 0 ).addClass( 'active' );
			selectChar = $( ul ).children( 'li' ).eq( 0 ).children( 'a' ).data( 'char' );
			methods.showCollection( ul );
		},
		showCollection : function( ul, category = null ){
			if( category !== null ) settings.category = category;
			$( ul ).next( '.collection-list' ).empty();
			if( alphabet[ settings.category ][ selectChar ].length > 11 ){
				var _tmp = methods.splitTo( alphabet[ settings.category ][ selectChar ], 11 );
				for( var i = 0; i < _tmp.length; i++ ) {
					$( ul ).next().append( '<ul></ul>' );
					var _tmpUL = $( ul ).next().children( 'ul' ).eq( i );
					var keys = Object.keys( _tmp[ i ] );
					keys.forEach( function( elem ) {
						_tmpUL.append( methods.createElement( _tmp[ i ][ elem ] ) );
					} );
				}
			} else {
				$( ul ).next().append( '<ul></ul>' );
				alphabet[ settings.category ][ selectChar ].forEach( function( elem ){
					 $( ul ).next().children( 'ul' ).append( methods.createElement( elem ) );
				} );
			}
		},
		createElement : function( obj){
			return $( '<li><a href="' + obj.link + '">' + obj.collection + '</a></li>' );
		},
		splitTo : function( arr, n ) {
			var plen = n;

			return arr.reduce( function ( p, c, i, a ) {
				if( i % plen === 0) p.push( {} );
					p[ p.length-1 ][ i ] = c;
					return p;
				}, [] );
		}
	};

	$.fn.collection = function( method ) {
		if ( methods[method] ) {
			return methods[ method ].apply( this, Array.prototype.slice.call( arguments, 1 ));
		} else if ( typeof method === 'object' || ! method ) {
			return methods.init.apply( this, arguments );
		} else {
			$.error( 'Метод с именем ' +  method + ' не существует для jQuery.Collection' );
		}
	};
})( jQuery );

$(document).ready(() => {
	
	$.get('assets/snippets/collections/collection.textile.json.php', (data) => {
		$( '[data-block="textiles"] ul.alphabet' ).collection( { category : 'textile', json: data } );
	});
});