import Cookies from "js-cookie";

(function ($) {
  var j_favorite = null,
    setting = {
      language: "ru",
      addText: true
    },
    methods = {
      init: function (options) {
        setting = $.extend(setting, options);
        j_favorite =
          Cookies.get("favorite") == undefined
            ? []
            : JSON.parse(Cookies.get("favorite"));
        if (j_favorite.length > 0) {
          for (var i = 0; i <= j_favorite.length; i++) {
            methods.showFavorite(j_favorite[i]);
          }
        }
      },

      showFavorite: function (id) {
        $('[data-productid="' + id + '"]')
          .addClass("active")
          .children("span")
          .html("В избранном");
      },

      addFavorite: function (obj) {
        j_favorite =
          Cookies.get("favorite") == undefined
            ? []
            : JSON.parse(Cookies.get("favorite"));
        var _indexElem = methods.existFavorite(obj.id, j_favorite);
        if (_indexElem == -1) {
          j_favorite.push(obj.id);
          $(obj.self)
            .addClass("active")
            .children("span")
            .html("В избранном");
        } else {
          j_favorite.splice(_indexElem, 1);
          $(obj.self)
            .removeClass("active")
            .children("span")
            .html("Добавить в избранное");
        }
        Cookies.set("favorite", JSON.stringify(j_favorite), {
          expires: 60,
          path: "/"
        });

        methods.updateHeaderCount();
      },

      deleteFavorite: function (obj) {
        j_favorite =
          Cookies.get("favorite") == undefined
            ? []
            : JSON.parse(Cookies.get("favorite"));
        var _indexElem = methods.existFavorite(obj.id, j_favorite);
        j_favorite.splice(_indexElem, 1);
        Cookies.set("favorite", JSON.stringify(j_favorite), {
          expires: 60,
          path: "/"
        });

        methods.updateHeaderCount();
        return true;
      },

      existFavorite: function (element, array) {
        for (var i = 0; i < array.length; i++) {
          if (array[i] == element) return i;
        }
        return -1;
      },

      countFavorite: function () {
        j_favorite =
          Cookies.get("favorite") == undefined
            ? []
            : JSON.parse(Cookies.get("favorite"));
        return j_favorite.length;
      },

      updateHeaderCount : function() {
        let _count = methods.countFavorite();

        if (_count > 0) {
          $(".header__actions__item--favorite").addClass("active");
          $(".header__actions__item--count").html(_count);
        } else {
          $(".header__actions__item--favorite").removeClass("active");
          $(".header__actions__item--count").html('');
        }
      }
    };

  $.fn.favorite = function (method) {
    if (methods[method]) {
      return methods[method].apply(
        this,
        Array.prototype.slice.call(arguments, 1)
      );
    } else if (typeof method === "object" || !method) {
      return methods.init.apply(this, arguments);
    } else {
      $.error(
        "Метод с именем " + method + " не существует для jQuery.favorite"
      );
    }
  };
})(jQuery);

$(document).ready(function () {
  let _count = $(document).favorite("countFavorite");

  $('.btn-delete-favorite').on('click', (e) => {
    $(e.currentTarget).parent().parent().fadeOut('fast', () => {
      $(e.currentTarget).parent().parent().remove();
    });
  });

  $(document).favorite();
  $("[data-productid]").on("click", function () {
    $(document).favorite("addFavorite", {
      id: $(this).data("productid"),
      self: this
    });

    _count = $(document).favorite("countFavorite");
    if (_count > 0) {
      $(".header__actions__item--favorite").addClass("active");
      $(".header__actions__item--count").html(_count);
    }
    return false;
  });

  if (_count > 0) {
    $(".header__actions__item--favorite").addClass("active");
    $(".header__actions__item--count").html(_count);
  }
});
