import Swiper from "swiper";
import "fancybox";
import { constants } from "zlib";

export default class Catalog {
  constructor() {
    let galleryThumbs = new Swiper(".js-catalog-slider-thumb", {
      spaceBetween: 20,
      slidesPerView: 7,
      freeMode: true,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      breakpoints: {
        // when window width is <= 320px
        767: {
          slidesPerView: 2,
          spaceBetween: 10
        },
        // when window width is <= 480px
        991: {
          slidesPerView: 4,
          spaceBetween: 20
        },
        // when window width is <= 640px
        1200: {
          slidesPerView: 5,
          spaceBetween: 30
        }
      }
    });
    new Swiper(".js-catalog-slider", {
      spaceBetween: 10,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      thumbs: {
        swiper: galleryThumbs
      }
    });
  }
}
