/**
 * Global var params
 */
import "jquery.nicescroll";
import "ion-rangeslider";
let URI = require("urijs");
import Collections from "./collections";
import Functions from "./functions";
import Wrapper from "./wrapper";

export default class Search {
  constructor() {
    this.uri = new URI(window.location.href).search(true);
    this.search = this.decodeURL();
    if (this.uri.price !== undefined) {
      let _tmp = this.uri.price.split(";");
      this.uri.price = {
        min: _tmp[0],
        max: _tmp[1]
      };
    }

    $(".search-panel").each((index, el) => {
      let _class = $.trim().split(" ");
      this.initFilterParams(el.className, index);
    });

    this.initFilterParams();

    $(".search-panel__header__search").on("submit", function() {
      if (
        $(this)
          .children("input")
          .val()
          .trim() === ""
      ) {
        return false;
      }
    });

    $(".nicescroll-box").niceScroll(".wrap", { cursorcolor: "aquamarine" });

    $(".search-panel__content__tab--range__input").ionRangeSlider({
      type: "double",
      min: params.wallpaper.price.min,
      max: params.wallpaper.price.max,
      from: this.uri.price !== undefined ? this.uri.price.min : 10,
      to: this.uri.price !== undefined ? this.uri.price.max : 600,
      drag_interval: true,
      min_interval: null,
      max_interval: null,
      hide_from_to: true,
      hide_min_max: true,
      onChange: function(data) {
        $(".search-panel__content__tab--rangeslider__from").html(
          `${data.from} р.`
        );
        $(".search-panel__content__tab--rangeslider__to").html(`${data.to} р.`);
        $(".search-panel__content__tab--price-min").prop("value", data.from);
        $(".search-panel__content__tab--price-max").prop("value", data.to);
      },
      onStart: function(data) {
        $(".search-panel__content__tab--rangeslider__from").html(
          `${data.from} р.`
        );
        $(".search-panel__content__tab--rangeslider__to").html(`${data.to} р.`);
        $(".search-panel__content__tab--price-min").prop("value", data.from);
        $(".search-panel__content__tab--price-max").prop("value", data.to);
      }
    });

    $(
      ".search-panel__content__tab--price-min, .search-panel__content__tab--price-max"
    ).on("change", e => {
      let ionRangeSlider = $(".search-panel__content__tab--range__input").data(
        "ionRangeSlider"
      );
      let min = $(".search-panel__content__tab--price-min").prop("value"),
        max = $(".search-panel__content__tab--price-max").prop("value");
      ionRangeSlider.update({
        from: min,
        to: max
      });

      $(".search-panel__content__tab--rangeslider__from").html(`${min} р.`);
      $(".search-panel__content__tab--rangeslider__to").html(`${max} р.`);
    });

    $(".header .header__search,.search-panel .btn-close-search").on(
      "click",
      function(e) {
        if ($(this).hasClass("header__search") && $(this).attr("href") != "#") {
          return true;
        }
        e.preventDefault();
        $(".search-panel:not(.search-panel_static)").fadeToggle("fast");
        $(".header__search .text_btn").toggleText(
          "Свернуть поиск",
          "Поиск в каталоге"
        );
        $(".header__search .arrow i").toggleClass("zmdi-chevron-up");
      }
    );
    $(
      ".search-panel .search-panel__header .search-panel__header__tabs li a"
    ).click(function(e) {
      e.preventDefault();
      let tab_id = $(this).attr("data-tab");

      $(this)
        .closest(".search-panel")
        .find(".search-panel__header .search-panel__header__tabs li")
        .removeClass("search-panel__header__tabs__current");
      $(this)
        .closest(".search-panel")
        .find(".search-panel__content .search-panel__content__tab")
        .removeClass("active");

      $(this)
        .parent()
        .addClass("search-panel__header__tabs__current");
      $(this)
        .closest(".search-panel")
        .find("#" + tab_id)
        .addClass("active");
    });

    /**
     * Init Wrapper panel
     */
    Wrapper.init();

    // var checkbox = $(
    //     ".search-panel__content__tab__params__checklist .checkbox input, .search-panel__content__tab__params__color input"
    //   ),
    //   checkbox_wrapper = $(".search-panel__content__tab__params__checklist"),
    //   checked_wrapper = $(
    //     ".search-panel .search-panel__content .search-panel__content__tab_checked_wrapper .checked_wrapper"
    //   );

    // function hide() {
    //   if (
    //     $(
    //       ".search-panel .search-panel__content .search-panel__content__tab .search-panel__content__tab__params__checklist .checkbox input:checked, .search-panel__content__tab__params__color input:checked"
    //     ).length == 0
    //   ) {
    //     $(
    //       ".search-panel .search-panel__content .search-panel__content__tab_checked_wrapper"
    //     ).toggleClass("active");
    //   }
    // }

    // $(checkbox).on("change", function() {
    //   let that_val = $(this)
    //       .next()
    //       .text(),
    //     that = $(this),
    //     data_class = that.closest(checkbox_wrapper).data("class"),
    //     data_title = that.closest(checkbox_wrapper).data("title"),
    //     data_block_class = checked_wrapper.find(
    //       `div[data-class='${data_class}']`
    //     ),
    //     input_length = that
    //       .closest(
    //         `.search-panel__content__tab__params__checklist[data-class='${data_class}']`
    //       )
    //       .find("input:checked").length,
    //     input = that
    //       .closest(
    //         `.search-panel__content__tab__params__checklist[data-class='${data_class}']`
    //       )
    //       .find("input");

    //   $(
    //     ".search-panel .search-panel__content .search-panel__content__tab_checked_wrapper"
    //   ).addClass("active");

    //   if (data_class) {
    //     if (input_length > 1) {
    //       checked_wrapper
    //         .find(data_block_class)
    //         .empty()
    //         .append(
    //           `<div class="checked_block"><div class="close_btn"></div>${input_length} ${data_title}</div>`
    //         );
    //     } else if (input_length == 0) {
    //       checked_wrapper.find(data_block_class).empty();
    //     } else {
    //       checked_wrapper
    //         .find(data_block_class)
    //         .empty()
    //         .append(
    //           `<div class="checked_block"><div class="close_btn"></div>${that_val}</div>`
    //         );
    //     }
    //   }
    // });

    // $("body").on("click", ".close_btn", function() {
    //   let parent_data = $(this)
    //     .closest("div[data-class]")
    //     .data("class");
    //   $(
    //     `.search-panel .search-panel__content .search-panel__content__tab .search-panel__content__tab__params__checklist[data-class="${parent_data}"]`
    //   )
    //     .find("input")
    //     .prop("checked", false);
    //   $(this)
    //     .closest("div[data-class]")
    //     .empty();
    //   hide();
    // });

    $(".header__mobile-btn").on("click", function(e) {
      e.preventDefault();
      $(".menu-overlay-block, .container-mobile-menu")
        .stop(true, true)
        .fadeIn("fast");
      $("body").css("overflow", "hidden");
      return false;
    });
    $(".menu-overlay-block ,.container-mobile-menu .close").on(
      "click",
      function() {
        $(".menu-overlay-block, .container-mobile-menu")
          .stop(true, true)
          .fadeOut("fast");
        $("body").css("overflow", "auto");
        return false;
      }
    );
    /*$(".header__actions__item--phone").on("click", function(e) {
      e.preventDefault();
      $(".hidden_phone_block").fadeToggle("fast");
    });
    $(document).mouseup(function(e) {
      var popup = $(".hidden_phone_block");
      if (
        !$(".header__actions__item--phone").is(e.target) &&
        !popup.is(e.target) &&
        popup.has(e.target).length == 0
      ) {
        popup.hide();
      }
    });*/
    if (screen.width < 767) {
      $("body").on("click", ".search-panel__content__tab h2", function() {
        $(this).toggleClass("active");
        $(this)
          .next()
          .slideToggle();
      });
      $("body").on("click", "#fotowallpaper h2", function() {
        $(this)
          .next()
          .find(".hidden-xs_two")
          .slideToggle();
      });
    }

    $(".search-panel__content__tab--cancel").on("click", function() {
      if ($(this).attr("href") === "#") {
        $(this)
          .closest("form")
          .find("input")
          .prop("checked", false);
        hide();
        return false;
      }
    });
    $.fn.extend({
      toggleText: function(a, b) {
        return this.text(this.text() == b ? a : b);
      }
    });

    // TODO: Закрывает панель фильтрации, если нажать на очистку выбранных параметров

    $(document).on("click", e => {
      if (
        !e.target.closest(".search-panel:not(.search-panel_static)") &&
        !$(e.target).hasClass("header__search") &&
        !$(e.target).hasClass("text_btn") &&
        !$(e.target).hasClass("arrow") &&
        !$(e.target).hasClass("zmdi-chevron-down") &&
        !$(e.target).hasClass("close_btn")
      ) {
        $(".search-panel:not(.search-panel_static)").fadeOut("fast");
        $(".header__search .text_btn").text("Поиск в каталоге");
        $(".header__search .arrow i").removeClass("zmdi-chevron-up");
      }
    });

    $(
      ".search-panel.search_smart .search-panel__content .search-panel__content__tab .min_height h2"
    ).on("click", function() {
      $(".search-panel.search_smart").toggleClass("visible");
    });

    if (this.uri.parent !== undefined) {
      $("[data-id]").removeClass("active");
      let tab = $(`[data-id="${this.uri.parent}"]`)
        .addClass("active")
        .prop("id");
      $(
        `.search-panel .search-panel__header .search-panel__header__tabs li`
      ).removeClass("search-panel__header__tabs__current");
      $(`.search-panel a[data-tab="${tab}"]`)
        .parent()
        .addClass("search-panel__header__tabs__current");
    }

    if (this.uri["only-discount"] !== undefined) {
      $(`[data-id="${this.uri.parent}"] input[name="only-discount"]`).prop(
        "checked",
        true
      );
    }

    if (this.uri["status"] !== undefined) {
      $(`[data-id="${this.uri.parent}"] input[name="status"]`).prop(
        "checked",
        true
      );
    }

    $('input[name="status"] + label').on("click", function() {
      let status = $(this)
        .prev()
        .prop("checked");
      $(this)
        .prev()
        .prop("checked", !status);
    });

    $('input[name="only-discount"] + label').on("click", function() {
      let status = $(this)
        .prev()
        .prop("checked");
      $(this)
        .prev()
        .prop("checked", !status);
    });
  }

  initFilterParams(el_class, index) {
    /**
     * Wallpaper Tab
     */

    /** Typeroom Param */

    Object.keys(params.wallpaper.typeroom.list).forEach(value => {
      $(
        `div[class="${el_class}"] #wallpaper .search-panel__content__tab__params__checklist[data-class="room"]`
      ).append(
        `<div class="checkbox">
					<input type="checkbox" id="wallpaper-typeroom-${index}-${value}" value="${value}" name="typeroom[]" ${
          this.search["typeroom"] !== undefined &&
          this.search["typeroom"].indexOf(value) !== -1
            ? 'checked="checked"'
            : ""
        } />
					<label for="wallpaper-typeroom-${index}-${value}">${
          params.wallpaper.typeroom.list[value]
        }</label>
				</div>`
      );
    });

    /** Material Param */
    Object.keys(params.wallpaper.p1.list).forEach(value => {
      $(
        `div[class="${el_class}"] #wallpaper .search-panel__content__tab__params__checklist[data-class="material"]`
      ).append(
        `<div class="checkbox">
					<input type="checkbox" name="p1[]" id="wallpaper-p1-${index}-${value}" value="${value}" ${
          this.search["p1"] !== undefined &&
          this.search["p1"].indexOf(value) !== -1
            ? 'checked="checked"'
            : ""
        } />
					<label for="wallpaper-p1-${index}-${value}">${
          params.wallpaper.p1.list[value]
        }</label>
				</div>`
      );
    });

    /** Design Param */
    Object.keys(params.wallpaper.p3.list).forEach(value => {
      $(
        `div[class="${el_class}"] #wallpaper .search-panel__content__tab__params__checklist[data-class="style"]`
      ).append(
        `<div class="checkbox">
					<input type="checkbox" name="p3[]" id="wallpaper-p3-${index}-${value}" value="${value}" ${
          this.search["p3"] !== undefined &&
          this.search["p3"].indexOf(value) !== -1
            ? 'checked="checked"'
            : ""
        } />
					<label for="wallpaper-p3-${index}-${value}">${
          params.wallpaper.p3.list[value]
        }</label>
				</div>`
      );
    });

    /** Brand Param */
    Object.keys(params.wallpaper.brand.list).forEach(value => {
      $(
        `div[class="${el_class}"] #wallpaper .search-panel__content__tab__params__checklist[data-class="brand"]`
      ).append(
        `<div class="checkbox">
					<input type="checkbox" name="brand[]" id="wallpaper-brand-${index}-${value}" value="${value}"  ${
          this.search["brand"] !== undefined &&
          this.search["brand"].indexOf(value) !== -1
            ? 'checked="checked"'
            : ""
        } />
					<label for="wallpaper-brand-${index}-${value}">${
          params.wallpaper.brand.list[value]
        }</label>
				</div>`
      );
    });

    /** Collection Param */
    new Collections(`div[class="${el_class}"] [data-collection]`, index);

    /** Colors Param */
    Object.keys(params.wallpaper.colors.list).forEach((value, key) => {
      Object.keys(params.wallpaper.colors.list[value]).forEach((color, i) => {
        $(
          `div[class="${el_class}"] #wallpaper .search-panel__content__tab__params__checklist[data-class="color"]`
        ).append(
          `<div class="search-panel__content__tab__params__color" style="background: ${
            params.wallpaper.colors.list[value][color]
          }">
						<input type="checkbox" id="wallpaper-color-${index}-${key}-${i}" name="colors[]" value="${color}" ${
            this.search["colors"] !== undefined &&
            this.search["colors"].indexOf(color) !== -1
              ? 'checked="checked"'
              : ""
          } />
						<label for="wallpaper-color-${index}-${key}-${i}">${color}</label>
					</div>`
        );
      });
      $(
        `div[class="${el_class}"] #wallpaper .search-panel__content__tab__params__checklist[data-class="color"]`
      ).append('<div class="clearfix"></div>');
    });

    /**
     * Photowallpaper
     */

    /** Design Param */

    let chunk = Functions.array_chunk(
      Object.keys(params.photowallpaper.p3.list),
      8
    );

    chunk.forEach(items => {
      let _html = "";
      items.forEach(value => {
        _html += `<div class="checkbox">
						<input type="checkbox" name="p3[]" id="photowallpaper-${index}-p3-${value}" value="${value}" ${
          this.search["p3"] !== undefined &&
          this.search["p3"].indexOf(value) !== -1
            ? 'checked="checked"'
            : ""
        }>
						<label for="photowallpaper-${index}-p3-${value}">${
          params.photowallpaper.p3.list[value]
        }</label>
				</div>`;
      });

      $(
        `div[class="${el_class}"] #fotowallpaper [data-class="photowallpaper"]`
      ).append(
        `<div class="col-md-2 search-panel__content__tab--checkbox search-panel__content__tab__params" style="padding: 0 15px">
					${_html}
				</div>`
      );
    });

    /**
     * Antivandal wallpaper
     */

    /** Design Param */
    Object.keys(params.antivandal.style.list).forEach(value => {
      $(
        `div[class="${el_class}"] #antivandal .search-panel__content__tab__params__checklist[data-class="style"]`
      ).append(
        `<div class="checkbox">
					<input type="checkbox" name="style[]" id="antivandal-style-${index}-${value}" value="${value}" ${
          this.search["style"] !== undefined &&
          this.search["style"].indexOf(value) !== -1
            ? 'checked="checked"'
            : ""
        }>
					<label for="antivandal-style-${index}-${value}">${
          params.antivandal.style.list[value]
        }</label>
				</div>`
      );
    });

    /** Colors Param */
    Object.keys(params.antivandal.colors.list).forEach((value, key) => {
      Object.keys(params.antivandal.colors.list[value]).forEach((color, i) => {
        $(
          `div[class="${el_class}"] #antivandal .search-panel__content__tab__params__checklist[data-class="color"]`
        ).append(
          `<div class="search-panel__content__tab__params__color" style="background: ${
            params.antivandal.colors.list[value][color]
          }">
						<input type="checkbox" id="antivandal-color-${index}-${key}-${i}" name="colors[]" value="${color}" ${
            this.search["colors"] !== undefined &&
            this.search["colors"].indexOf(value) !== -1
              ? 'checked="checked"'
              : ""
          }>
						<label for="antivandal-color-${index}-${key}-${i}">${color}</label>
					</div>`
        );
      });
      $(
        `div[class="${el_class}"] #antivandal .search-panel__content__tab__params__checklist[data-class="color"]`
      ).append('<div class="clearfix"></div>');
    });

    /**
     * Cloth
     */

    /** Country Param */
    params.cloth["country-list"].list.forEach((value, key) => {
      $(
        `div[class="${el_class}"] #cloth .search-panel__content__tab__params__checklist[data-class="country"]`
      ).append(
        `<div class="checkbox">
					<input type="checkbox" name="country-list[]" id="cloth-country-${index}-${key}" value="${value}" ${
          this.search["country-list"] !== undefined &&
          this.search["country-list"].indexOf(value) !== -1
            ? 'checked="checked"'
            : ""
        }>
					<label for="cloth-country-${index}-${key}">${value}</label>
				</div>`
      );
    });

    /** Design Param */
    Object.keys(params.cloth.designcheckbox.list).forEach(value => {
      $(
        `div[class="${el_class}"] #cloth .search-panel__content__tab__params__checklist[data-class="style"]`
      ).append(
        `<div class="checkbox">
					<input type="checkbox" name="designcheckbox[]" id="cloth-style-${index}-${value}" value="${value}" ${
          this.search["designcheckbox"] !== undefined &&
          this.search["designcheckbox"].indexOf(value) !== -1
            ? 'checked="checked"'
            : ""
        }>
					<label for="cloth-style-${index}-${value}">${
          params.cloth.designcheckbox.list[value]
        }</label>
				</div>`
      );
    });

    /** Appointment Param */
    Object.keys(params.cloth.appointment.list).forEach(value => {
      $(
        `div[class="${el_class}"] #cloth .search-panel__content__tab__params__checklist[data-class="appointment"]`
      ).append(
        `<div class="checkbox">
					<input type="checkbox" name="appointment[]" id="cloth-appointment-${index}-${value}" value="${value}" ${
          this.search["appointment"] !== undefined &&
          this.search["appointment"].indexOf(value) !== -1
            ? 'checked="checked"'
            : ""
        }>
					<label for="cloth-appointment-${index}-${value}">${
          params.cloth.appointment.list[value]
        }</label>
				</div>`
      );
    });

    /** Stuffsearch Param */
    Object.keys(params.cloth.stuffsearch.list).forEach(value => {
      $(
        `div[class="${el_class}"] #cloth .search-panel__content__tab__params__checklist[data-class="stuff"]`
      ).append(
        `<div class="checkbox">
					<input type="checkbox" name="stuffsearch[]" id="cloth-stuffsearch-${index}-${value}" value="${value}" ${
          this.search["stuffsearch"] !== undefined &&
          this.search["stuffsearch"].indexOf(value) !== -1
            ? 'checked="checked"'
            : ""
        }>
					<label for="cloth-stuffsearch-${index}-${value}">${
          params.cloth.stuffsearch.list[value]
        }</label>
				</div>`
      );
    });

    /** Colors Param */
    Object.keys(params.cloth.colors.list).forEach((value, key) => {
      Object.keys(params.cloth.colors.list[value]).forEach((color, i) => {
        $(
          `div[class="${el_class}"] #cloth .search-panel__content__tab__params__checklist[data-class="color"]`
        ).append(
          `<div class="search-panel__content__tab__params__color" style="background: ${
            params.cloth.colors.list[value][color]
          }">
						<input type="checkbox" id="cloth-color-${index}-${key}-${i}" name="colors[]" value="${color}" ${
            this.search["colors"] !== undefined &&
            this.search["colors"].indexOf(color) !== -1
              ? 'checked="checked"'
              : ""
          } />
						<label for="cloth-color-${index}-${key}-${i}">${color}</label>
					</div>`
        );
      });
      $(
        `div[class="${el_class}"] #cloth .search-panel__content__tab__params__checklist[data-class="color"]`
      ).append('<div class="clearfix"></div>');
    });

    /**
     * Accessories
     */

    /** Brand Param */
    Object.keys(params.accessories.brand.list).forEach((value, key) => {
      $(
        `div[class="${el_class}"] #accessories .search-panel__content__tab__params__checklist[data-class="brand"]`
      ).append(
        `<div class="checkbox">
					<input type="checkbox" name="brand[]" id="accessories-brand-${index}-${key}" value="${value}" ${
          this.search["brand"] !== undefined &&
          this.search["brand"].indexOf(value) !== -1
            ? 'checked="checked"'
            : ""
        }>
					<label for="accessories-brand-${index}-${key}">${
          params.accessories.brand.list[value]
        }</label>
				</div>`
      );
    });

    /** View Param */
    Object.keys(params.accessories.view_accessory.list).forEach(value => {
      $(
        `div[class="${el_class}"] #accessories .search-panel__content__tab__params__checklist[data-class="view"]`
      ).append(
        `<div class="checkbox">
					<input type="checkbox" name="view_accessory[]" id="accessories-view-${index}-${value}" value="${value}" ${
          this.search["view_accessory"] !== undefined &&
          this.search["view_accessory"].indexOf(value) !== -1
            ? 'checked="checked"'
            : ""
        }>
					<label for="accessories-view-${index}-${value}">${
          params.accessories.view_accessory.list[value]
        }</label>
				</div>`
      );
    });

    /** Appointment Param */
    Object.keys(params.accessories.appointment_accessory.list).forEach(
      value => {
        $(
          `div[class="${el_class}"] #accessories .search-panel__content__tab__params__checklist[data-class="appointment"]`
        ).append(
          `<div class="checkbox">
					<input type="checkbox" name="appointment_accessory[]" id="accessories-appointment_accessory-${index}-${value}" value="${value}" ${
            this.search["appointment_accessory"] !== undefined &&
            this.search["appointment_accessory"].indexOf(value) !== -1
              ? 'checked="checked"'
              : ""
          }>
					<label for="accessories-appointment_accessory-${index}-${value}">${
            params.accessories.appointment_accessory.list[value]
          }</label>
				</div>`
        );
      }
    );

    Object.keys(params.accessories.colors.list).forEach((value, key) => {
      Object.keys(params.accessories.colors.list[value]).forEach((color, i) => {
        $(
          `div[class="${el_class}"] #accessories .search-panel__content__tab__params__checklist[data-class="color"]`
        ).append(
          `<div class="search-panel__content__tab__params__color" style="background: ${
            params.accessories.colors.list[value][color]
          }">
						<input type="checkbox" id="accessories-color-${index}-${key}-${i}" name="colors[]" value="${color}" ${
            this.search["colors"] !== undefined &&
            this.search["colors"].indexOf(color) !== -1
              ? 'checked="checked"'
              : ""
          } />
						<label for="accessories-color-${index}-${key}-${i}">${color}</label>
					</div>`
        );
      });
      $(
        `div[class="${el_class}"] #accessories .search-panel__content__tab__params__checklist[data-class="color"]`
      ).append('<div class="clearfix"></div>');
    });

    $('input[name="typeroom"][value="type01"]').attr(
      "data-disabled",
      "type05,type06"
    );
    $('input[name="typeroom"][value="type05"]').attr("data-disabled", "type01");
    $('input[name="typeroom"][value="type06"]').attr("data-disabled", "type01");

    $.expr[":"].valuein = function(obj, index, meta, stack) {
      return meta[3]
        .toString()
        .split(",")
        .indexOf($(obj).prop("value")) !== -1
        ? true
        : false;
    };

    $.expr[":"].datain = function(obj, index, meta, stack) {
      let _arrayDisabled = [],
        flg = false;
      if ($(obj).data("disabled") == undefined) return false;
      meta[3] = meta[3].toString().split(",");
      meta[3].forEach(function(item) {
        if (
          $(obj)
            .data("disabled")
            .indexOf(item) !== -1
        ) {
          flg = true;
        }
      });
      return flg;
    };

    $("[data-disabled]").on("change", function() {
      if ($(this).prop("checked")) {
        $(this)
          .parent()
          .parent()
          .find(
            'input[name="' +
              $(this).prop("name") +
              '"]:valuein("' +
              $(this).data("disabled") +
              '")'
          )
          .attr("disabled", "disabled")
          .next()
          .css("opacity", ".5");
      }
      if (
        !$(this).prop("checked") &&
        $(this)
          .parent()
          .parent()
          .find(
            'input[name="' +
              $(this).attr("name") +
              '"]:checked:datain("' +
              $(this).data("disabled") +
              '")'
          ).length == 0
      ) {
        $(this)
          .parent()
          .parent()
          .find(
            'input[name="' +
              $(this).prop("name") +
              '"]:valuein("' +
              $(this).data("disabled") +
              '")'
          )
          .removeAttr("disabled")
          .next()
          .css("opacity", "1");
      }
    });

    $("[data-disabled]:checked").each(function() {
      let _values = $(this).data("disabled");
      $('[data-disabled]:valuein("' + _values + '")')
        .attr("disabled", "disabled")
        .next()
        .css("opacity", ".5");
    });
  }

  decodeURL() {
    const search = [];
    if (window.location.search == "") return typeof (filterParams) == 'undefined' ? [] : filterParams;
    tmp = window.location.search
      .replace("?", "")
      .replace(/\+/gi, " ")
      .split(/&/);
    tmp.forEach(function(key) {
      var param = /[a-z0-9_-]+/gi.exec(decodeURIComponent(key))[0];
      if (
        search === null ||
        search[param] === undefined ||
        typeof search[param] !== "object"
      )
        search[param] = [];
      search[param].push(/=(.*)/gi.exec(decodeURIComponent(key))[1]);
    });

    return search;
  }
}
