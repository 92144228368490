import "owl.carousel";
import { resolve } from "path";

export default class Homepage {
  constructor() {
    let promise = new Promise((resolve, reject) => {
      resolve(this.init());
    });

    promise.then(() => {
      window.lazyload.update();
      console.log('Called lazyload');
    });
  }

  init() {
    $("#slider__block").owlCarousel({
      items: 1,
      dots: true,
      nav: true,
      loop: true,
      autoplay: 2000,
      navText: [],
      onInitialized: counter,
      onChanged: counter,
      lazyLoad: true,
      autoHeight: true
    });

    function counter(event) {
      if (!event.namespace) {
        return;
      }
      var slides = event.relatedTarget;
      $('.slider__block__item__slide').text(slides.relative(slides.current()) + 1 + '/' + slides.items().length);
    }

    let sliders = $(".slider > .owl-carousel").owlCarousel({
      items: 1,
      mouseDrag: false,
      touchDrag: false,
      dots: false,
      lazyLoad: true,
    });

    $('.fixed_price .btn-favorite').on('click', function () {
      $(this).toggleText('Добавить в изюранное', 'Добавлено в избранное');
    });

    $('[data-style="gold"]').owlCarousel({
      center: true,
      loop: true,
      margin: 30,
      nav: false,
      lazyLoad: true,
      responsive: {
        0: {
          items: 1,
          loop: true,
          center: true,
          dots: false,
          mergeFit: true,
          stagePadding: 50,
          autoHeight: true
        },
        768: {
          items: 2,
          loop: true,
          center: true,
          dots: false,
          mergeFit: true,
          stagePadding: 30,
          autoHeight: true
        },
        992: {
          items: 4,
          loop: true,
          center: true,
          dots: false,
          mergeFit: true,
          autoWidth: true,
          stagePadding: 30
        },
        1200: {
          items: 6,
          dots: false
        }
      }
    });

    $('[data-style="dark"]').owlCarousel({
      center: true,
      loop: true,
      margin: 30,
      nav: false,
      dots: false,
      lazyLoad: true,
      responsive: {
        0: {
          items: 1,
          loop: true,
          center: true,
          dots: false,
          mergeFit: true,
          stagePadding: 50,
          autoHeight: true
        },
        768: {
          items: 2,
          loop: true,
          center: true,
          dots: false,
          mergeFit: true,
          stagePadding: 30,
          autoHeight: true
        },
        992: {
          items: 3,
          loop: true,
          center: true,
          dots: false,
          mergeFit: true,
          autoWidth: true,
          stagePadding: 30
        },
        1200: {
          items: 4,
          dots: false
        }
      }
    });

    $(".sliders__button__list__item > a").on("click", function () {
      $(this)
        .parent()
        .parent()
        .find(".sliders__button__list__item")
        .removeClass("sliders__button__list__item--current");
      $(this)
        .parent()
        .addClass("sliders__button__list__item--current");
      sliders
        .eq(
          $(this)
            .parent()
            .parent()
            .data("slider")
        )
        .trigger(
          "to.owl.carousel",
          $(this)
            .parent()
            .index()
        );
      return false;
    });

    $(".btn-to-top").click(function () {
      $("html, body").animate({ scrollTop: 0 }, "slow");
      return false;
    });

    //$( '.filter-btn' ).fitler();

    $(".float-block > ul > li").on("click", function () {
      if (
        !$(this)
          .parent()
          .hasClass("opened")
      ) {
        $(this)
          .parent()
          .addClass("opened");
      }
      $(".float-block > ul > li").removeClass("active");
      $(this).addClass("active");
      $(".float-block div")
        .hide()
        .eq($(this).index())
        .show();
      $(this)
        .parent()
        .parent()
        .stop(true, true)
        .animate(
          {
            right: 0
          },
          500
        );
    });

    $(document).click(function (event) {
      if (!$(event.target).closest(".float-block").length) {
        $(".float-block")
          .stop(true, true)
          .animate(
            {
              right: "-300px"
            },
            500
          );
        $(".float-block > ul").removeClass("opened");
        $(".float-block > ul > li").removeClass("active");
      }
    });

    $("#mob-menu").owlCarousel({
      items: 4,
      dots: false
    });

    var sliderIntoSlider = $("#slider-into-slider");
    sliderIntoSlider.owlCarousel({
      items: 1,
      dots: false,
      mouseDrag: false
    });

    //stopOwlPropagation('.owl-carousel');

    $('[data-element="wallpaper"] a').click(function () {
      $('[data-element="wallpaper"] a').removeClass("active");
      $(this).addClass("active");
      sliderIntoSlider.trigger("to.owl.carousel", [$(this).data("item")]);
      return false;
    });

    var wt = $("#wallpaper-type").owlCarousel({
      items: 5,
      dots: false,
      margin: 30,
      loop: true,
      mouseDrag: false,
      onChanged: function (event) {
        if ($(window).width() > 1200) {
          $(event.target)
            .find(".item")
            .removeClass("blur");
          $(event.target)
            .find(".owl-item")
            .eq(event.item.index)
            .children("div.item")
            .addClass("blur");
          $(event.target)
            .find(".owl-item")
            .eq(event.item.index + 4)
            .children("div.item")
            .addClass("blur");
        }
      },
      responsive: {
        0: {
          items: 1
        },
        768: {
          items: 3
        },
        1200: {
          items: 5
        }
      }
    });

    var wr = $("#wallpaper-room");
    wr.owlCarousel({
      items: 5,
      dots: false,
      margin: 30,
      loop: true,
      mouseDrag: false,
      onChanged: function (event) {
        if ($(window).width() > 1200) {
          $(event.target)
            .find(".item")
            .removeClass("blur");
          $(event.target)
            .find(".owl-item")
            .eq(event.item.index)
            .children("div.item")
            .addClass("blur");
          $(event.target)
            .find(".owl-item")
            .eq(event.item.index + 4)
            .children("div.item")
            .addClass("blur");
        }
      },
      responsive: {
        0: {
          items: 1
        },
        768: {
          items: 3
        },
        1200: {
          items: 5
        }
      }
    });

    $("#range-slider")
      .children("div")
      .each(function () {
        $(this)
          .children(".range-item")
          .slice(0, 4)
          .show();
      });

    $("#range-slider .btn-all").click(function () {
      $(this)
        .fadeOut("fast")
        .parent()
        .parent()
        .children("div.range-item")
        .stop(true, true)
        .fadeIn("fast");
      return false;
    });

    var rs = $("#range-slider").owlCarousel({
      items: 1,
      dots: false,
      mouseDrag: false
    });

    $('[data-element="range"] a').click(function () {
      $('[data-element="range"] a').removeClass("active");
      $(this).addClass("active");
      $(".range-item").hide();
      $("#range-slider .btn-all").fadeIn("fast");
      $("#range-slider")
        .find(".owl-item")
        .children("div")
        .each(function () {
          $(this)
            .children(".range-item")
            .slice(0, 4)
            .show();
        });
      rs.trigger("to.owl.carousel", [$(this).data("item")]);
      return false;
    });

    $("#wallpaper-type,#wallpaper-room").append(
      '<div class="btn-prev"><</div><div class="btn-next">></div>'
    );
    $("#real-photos").owlCarousel({
      items: 1,
      mouseDrag: false,
      lazyLoad: true
    });

    var brand = $("#brands").owlCarousel({
      items: 4,
      dots: false,
      margin: 30,
      lazyLoad: true
    });

    var review = $("#reviews").owlCarousel({
      items: 2,
      dots: false,
      margin: 30,
      responsive: {
        0: {
          items: 1
        },
        768: {
          items: 2
        },
        1200: {
          items: 2
        }
      }
    });

    $("#reviews").append(
      '<div class="btn-prev visible-md"><</div><div class="btn-next visible-md">></div>'
    );
    $(".btn-prev").click(function (e) {
      if ($(this).data("target") == "#brands") {
        brand.trigger("prev.owl.carousel");
      }
      if (
        $(this)
          .parent()
          .prop("id") == "wallpaper-type"
      ) {
        wt.trigger("prev.owl.carousel");
      } else if (
        $(this)
          .parent()
          .prop("id") == "wallpaper-room"
      ) {
        wr.trigger("prev.owl.carousel");
      } else if (
        $(this)
          .parent()
          .prop("id") == "reviews"
      ) {
        review.trigger("prev.owl.carousel");
      }
    });

    $(".btn-next").click(function (e) {
      if ($(this).data("target") == "#brands") {
        brand.trigger("next.owl.carousel");
      }
      if (
        $(this)
          .parent()
          .prop("id") == "wallpaper-type"
      ) {
        wt.trigger("next.owl.carousel");
      } else if (
        $(this)
          .parent()
          .prop("id") == "wallpaper-room"
      ) {
        wr.trigger("next.owl.carousel");
      } else if (
        $(this)
          .parent()
          .prop("id") == "reviews"
      ) {
        review.trigger("next.owl.carousel");
      }
    });

    $("li[data-hover]").hover(
      function () {
        $('div[data-block="' + $(this).data("hover") + '"]')
          .stop(true, true)
          .show();
      },
      function () {
        $('div[data-block="' + $(this).data("hover") + '"]')
          .stop(true, true)
          .hide();
      }
    );

    $("div[data-block]").hover(
      function () {
        $(this)
          .stop(true, true)
          .show()
          .addClass("current");
        $('li[data-hover="' + $(this).data("block") + '"]').addClass("current");
      },
      function (e) {
        $(this)
          .stop(true, true)
          .fadeOut("fast")
          .removeClass("current");
        $('li[data-hover="' + $(this).data("block") + '"]').removeClass(
          "current"
        );
      }
    );

    $("li[data-image]").mouseover(function () {
      $(this)
        .parent()
        .parent()
        .prev()
        .children("img")
        .prop("src", $(this).data("image"));
    });

    $(".msb").owlCarousel({
      items: 1,
      loop: true,
      dots: true
    });
    var scrollPos = 0;
    // adding scroll event
    window.addEventListener("scroll", function () {
      // detects new state and compares it with the new one
      if (document.body.getBoundingClientRect().top > scrollPos)
        document.querySelector(".header").classList.remove("fixed_top");
      else document.querySelector(".header").classList.add("fixed_top");
      // saves the new position for iteration.
      scrollPos = document.body.getBoundingClientRect().top;
    });
    $(function () {
      var header = $(".header");
      $(window).scroll(function () {
        var scroll = $(window).scrollTop();

        if (scroll >= 100) {
          header.addClass("fixed");
        } else {
          header.removeClass("fixed");
        }
      });
    });
    /*$(".filter-panel-buttons .filter-btn-curtains li span").click(function() {
      $(this)
        .parent()
        .toggleClass("active");
      $(this)
        .next()
        .slideToggle();
    });*/
    $(".new_product_card").on({
      mouseenter: function () {
        $(this).addClass('hover');
      },
      mouseleave: function () {
        $(this).removeClass('hover');
      }
    });
    $(
      ".new_filter_wrapper .filter-panel-buttons .filter-btn-curtains .dropdown-block label"
    ).on("click", function () {
      $(this)
        .closest("li")
        .find("span")
        .text($(this).text());
      var dtl = $(this).text().length;
      console.log(dtl);
      if (dtl > 10) {
        $(this)
          .closest("li")
          .find("span")
          .text(
            $(this)
              .closest("li")
              .find("span")
              .text()
              .substr(0, 10) + "..."
          );
      }
    });
  }
}
