import Swiper from "swiper";

export default class Oboi {
  constructor() {
    $(".new_product_card .gallery-thumbs").each(function(index) {
      new Swiper(this, {
        spaceBetween: 15,
        slidesPerView: 6,
        direction: "vertical",
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        navigation: {
          nextEl: $(this).find(".swiper-button-next"),
          prevEl: $(this).find(".swiper-button-prev")
        }
      });
    });
    /*$('.download-texture > a').click(function(e){
			e.preventDefault();
			$(this).next().slideToggle();
		});*/
  }
}
