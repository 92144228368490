import InfiniteScroll from "infinite-scroll";

export default class Loader {
  constructor(options) {
    this.options = {
      btnLoadingClass: "loading--more",
      paginationContainerClass: "pagination",
      btnLoadingText: "Показать все",
      classContainerElements: undefined,
      nextBtn: ".pagination > .arrow-next",
      elementClass: ".filter-goods-result .col-md-3",
      callbackAfterAppendItems: undefined,
    };

    this.options = $.extend(this.options, options);
    this.init();
  }

  getNewID() {
    return [...Array(10)].map(i => (~~(Math.random() * 36)).toString(36)).join('');
  }

  init() {
    this.options.btnLoaderID = this.getNewID();
    $(`.${this.options.paginationContainerClass}`).after(`<a href="#" id="${this.options.btnLoaderID}" class="${this.options.btnLoadingClass}">${this.options.btnLoadingText}</a>`);

    $(`#${this.options.btnLoaderID}`).on('click', e => {
      e.preventDefault();

      $(e.currentTarget).hide();

      this.options.containerElements = document.querySelector(`.${this.options.classContainerElements}`);
      this.InfiniteScroll = new InfiniteScroll(this.options.containerElements, {
        // options
        path: this.options.nextBtn,
        append: this.options.elementClass,
        hideNav: `.${this.options.paginationContainerClass}`,
        history: false
      });


      this.InfiniteScroll.on('append', (response, path, items) => {
        this.options.callbackAfterAppendItems(items);
      });

      this.InfiniteScroll.loadNextPage();
    });

  }
}
