import "./scss/main.scss";
import LazyLoad from "vanilla-lazyload";
import Homepage from "./modules/mainpage";
import Search from "./modules/search";
import "@fancyapps/fancybox";
import Oboi from "./modules/oboi";
import Catalog from "./modules/catalog";
import Portfolio from "./modules/portfolio";
import Curtains from "./modules/curtains";
import Loader from "./modules/loader";
import Swiper from "swiper";
import Product from "./modules/product";
require("./modules/favorite.js");
require("./modules/jquery.collection.js");

$(document).ready(() => {
  const lazyLoadInstance = new LazyLoad({
    elements_selector: ".lazyload"
  });

  window.lazyload = lazyLoadInstance;

  window.ref = {
    homeRef: new Homepage(),
    searchRef: new Search(),
    oboiRef: new Oboi(),
    catalogRef: new Catalog(),
    portfolioRef: new Portfolio(),
    productRef: new Product(),
    curtainsRef: new Curtains()    
  };

  if ($('.filter-goods-result:not(.filter-curtans)').length) {
    const searchPage = new Loader({
      btnLoadingClass: 'show-all transition',
      classContainerElements: "filter-goods-result",
      callbackAfterAppendItems: (items) => {
        $(items).each((index, element) => {

          element = $(element).find('.gallery-thumbs');

          new Swiper(element, {
            spaceBetween: 15,
            slidesPerView: 6,
            direction: "vertical",
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            navigation: {
              nextEl: $(element).find(".swiper-button-next"),
              prevEl: $(element).find(".swiper-button-prev")
            }
          });
        });
      }
    });
  }
});
