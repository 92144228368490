export default class Curtains {
  constructor() {
    if (window.location.hash) {
      const $hash = window.location.hash.substring(1);
      if($(`[data-hash="${$hash}"]`).length) {
        const $top = $(`[data-hash="${$hash}"]`).offset().top;

        setTimeout(() => {
          $("html,body").scrollTop($top - 150);
        }, 1500);
      }
    }
  }
}
